import { React, useState, useEffect } from "react";
import style from "./information.module.css"

export function Information() {
    const [mobile, setMobile] = useState('false')

    return (
        <div>

            <div className={`${style['fill-window']}`} id="fungerar">
                <section className="section mt-6">
                    <div className="container ">

                        <h1 className="title has-text-centered pt-6">Hur Fungerar det?</h1>
                        <div className="columns pt-5 is-centered is-hidden-desktop">
                            <div className="px-4 pb-4">
                                <div className={`box ${style.test2}`}>
                                    <div className={`far fa-calendar-check ${style.iconSize}`}></div>
                                    <span className="is-size-5">Boka en tid</span>
                                    <p className="content">Hör av dig via bokningsformuläret kring vilken typ av fotografering som önskas. Återkoppling sker efter klockan 18.00
                                    </p>
                                </div>

                            </div>

                            <div className="px-4 pb-4">

                                <div className={`box ${style.test2}`}>
                                    <div className={`fas fa-dollar-sign ${style.iconSize}`}></div>
                                    <span className="is-size-5">Betalning</span>
                                    <p className="content">Sker i samband med fotograferingen. Kontant eller Swish eller mot faktura</p>
                                </div>

                            </div>

                            <div className="px-4 pb-4">
                                <div className={`box ${style.test2}`}>
                                    <div className={`fas fa-map-marker-alt ${style.iconSize}`}></div>
                                    <span className="is-size-5">Genomförandet</span>
                                    <p className="content">Fotograferingen sker sedan vardagskvällar efter kl 18 eller helgdagar i mån av tid, dock senast kl 21. Observera att
                                        bokning är bindande och att uteblivna besök faktureras! Ombokning är möjligt vid godtagbara
                                        skäl, såsom sjukdom eller liknande.</p>

                                </div>
                            </div>
                            <div className="px-4 pb-4">
                                <div className={`box ${style.test2}`}>
                                    <div className={`fas fa-truck ${style.iconSize}`}></div>
                                    <span className="is-size-5">Leverans.</span>
                                    <p className="content">Dina bilder levereras efterbehandlade digitalt inom 14 dagar.</p>
                                </div>
                            </div>

                            <div className="px-4 pb-4">
                                <div className={`box ${style.test2}`}>
                                    <div className={`far fa-copyright ${style.iconSize}`}></div>
                                    <span className="is-size-5">Copyright</span>
                                    <p className="content">Jag som fotograf har alltid rättigheterna till bilderna och förbehåller mig rätten att använda
                                        dem i min egen marknadsföring, ex hemsida och liknande. Jag kommer dock ej använda mig av dejting profilbilder då detta enbart är för dig.</p>
                                </div>
                            </div>


                        </div>

                        <div className="columns pt-5 is-centered is-desktop is-hidden-mobile">
                            <div className="px-4 pb-4">
                                <div className={`box ${style.test}`}>
                                    <div className={`far fa-calendar-check ${style.iconSize}`}></div>
                                    <span className="is-size-5">Boka en tid</span>
                                    <p className="content">Hör av dig via bokningsformuläret kring vilken typ av fotografering som önskas. Återkoppling sker efter klockan 18.00
                                    </p>
                                </div>

                            </div>

                            <div className="px-4 pb-4">

                                <div className={`box ${style.test}`}>
                                    <div className={`fas fa-dollar-sign ${style.iconSize}`}></div>
                                    <span className="is-size-5">Betalning</span>
                                    <p className="content">Sker i samband med fotograferingen. Kontant eller Swish eller mot faktura</p>
                                </div>

                            </div>

                            <div className="px-4 pb-4">
                                <div className={`box ${style.test}`}>
                                    <div className={`fas fa-map-marker-alt ${style.iconSize}`}></div>
                                    <span className="is-size-5">Genomförandet</span>
                                    <p className="content">Fotograferingen sker sedan vardagskvällar efter kl 18 eller helgdagar i mån av tid, dock senast kl 21. Observera att
                                        bokning är bindande och att uteblivna besök faktureras! Ombokning är möjligt vid godtagbara
                                        skäl, såsom sjukdom eller liknande.</p>

                                </div>
                            </div>
                            <div className="px-4 pb-4">
                                <div className={`box ${style.test}`}>
                                    <div className={`fas fa-truck ${style.iconSize}`}></div>
                                    <span className="is-size-5">Leverans.</span>
                                    <p className="content">Dina bilder levereras efterbehandlade digitalt inom 14 dagar.</p>
                                </div>
                            </div>

                            <div className="px-4 pb-4">
                                <div className={`box ${style.test}`}>
                                    <div className={`far fa-copyright ${style.iconSize}`}></div>
                                    <span className="is-size-5">Copyright</span>
                                    <p className="content">Jag som fotograf har alltid rättigheterna till bilderna och förbehåller mig rätten att använda
                                        dem i min egen marknadsföring, ex hemsida och liknande. Jag kommer dock ej använda mig av dejting profilbilder då detta enbart är för dig.</p>
                                </div>
                            </div>


                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

/*
        <div className={`has-background-danger-light ${style['fill-window']}`}>
        <span className={`is-size-2 ${style.orderHeadline}`}>Hur fungerar det?</span>
        <div className={`${style.orderBox} ${style.left}`}>
        <div className={`box ${style.info}`}>
        <div className="is-flex-direction-column">
        <div className={`far fa-calendar-check ${style.iconSize}`}></div>
        <span className={`is-size-5 is-uppercase`}>Boka en tid<br/></span>
        <span className={`is-size-8 ${style['enable-line-break']}`}>Hör av dig via boknings formuläret.</span>
        </div>
        </div>
        <div className={`box ${style.info}`}>
        <div className={`fas fa-map-marker-alt ${style.iconSize}`}></div>
        <span className={`is-size-5 is-uppercase`}>Olika miljöer<br/></span>
        <span className={`is-size-8 ${style['enable-line-break']}`}>Bilderna kommer tas på olika lokationer för att skapa en variation av naturliga bilder.</span>
        </div>
        <div className={`box ${style.info}`}>
        <div className={`fas fa-camera ${style.iconSize}`}></div>
        <span className={`is-size-5 is-uppercase `}>Leverans<br/></span>
        <span className={`is-size-8 ${style['enable-line-break']}`}>Dina bilder levereras efterbehandlade digitalt inom 14 dagar</span>
        </div>
        </div>
        </div>

*/

/*
        <div className={`has-background-danger-light`}>
            <div className="is-align-items-center">

            <div className="container">
            <div className="columns is-mobile column is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div className="box has-text-centered has-text-centered	">
                <div className={`far fa-calendar-check ${style.iconSize}`}></div>
                <span className={`is-size-5 is-uppercase`}>Boka en tid<br/></span>
                <span className={"is-size-8"}>Hör av dig via boknings formuläret.</span>
                </div>

                <div className="box has-text-centered">
                <div className={`far fa-calendar-check ${style.iconSize}`}></div>
                <span className="is-size-5 is-uppercase">Boka en tid<br/></span>
                <span className="is-size-8">Hör av dig via boknings formuläret.</span>
                </div>

                <div className="box has-text-centered">
                <div className={`far fa-calendar-check ${style.iconSize}`}></div>
                <span className="is-size-5 is-uppercase">Boka en tid<br/></span>
                <span className="is-size-8">Hör av dig via boknings formuläret.</span>
                </div>
                </div>
                </div>

            </div>
        </div>

*/


/*
            <h1 className="py-6 my-6">Hello, Bulma</h1>
            <h1 className="px-4 mx-6">Hello, Bulma</h1>
            <h1 className="mt-5">Hello, Bulma</h1>
            <h1 className="mb-5">Hello, Bulma</h1>

            <section className="section">
                <p>hello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlkhello Worldasdajsdkjaslkdjaklsjdlkajslkdjalksjdlkajsdlkjalskdjlkasjdlkasjdlkasjdlk</p>
            </section>
            <div className="section">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam neque natus explicabo blanditiis assumenda consequatur illo tenetur. Beatae eius voluptatem minus, fugiat obcaecati repellat libero, voluptates dolorum veniam nemo facilis.</p>
            </div>

            <div className="section">
            <div className="container">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quam natus, ducimus cum nihil deleniti veniam ipsam earum tenetur ex magnam! Beatae nostrum magnam fuga cum aliquid? A aliquid culpa magnam!</p>
            </div>
            </div>

*/

/*
   const burgerIcon = document.querySelector('#burger');
    const navbarMeny = document.querySelector('#nav-links');

    burgerIcon.addEventListener('click',() => {
        navbarMeny.classList.toggle('is-active')
    })

*/


/*
      <nav className="navbar">

            <div className="navbar-brand">
                <a className="navbar-item">
                    <div>Hello</div>
                </a>
                <a className="navbar-burger" id="burger">
                    <span></span>
                    <span></span>
                    <span></span>
                </a>
            </div>

            <div className="navbar-meny" id="nav-links">
                <div className="navbar-end">
                <a className="navbar-item">
                    <div>Hello</div>
                </a>
                <a className="navbar-item">
                    <div>Hello</div>
                </a>
                </div>
            </div>

        </nav>

*/