import { React, useState } from "react";
import style from "./navbar.module.css";
import { Link } from 'react-scroll';

export function NavBar() {
    const [isActive, setisActive] = useState(false);

    const DisableBurger = () => {
        setisActive(false)
    }

    return (
        <nav className="navbar is-fixed-top" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <a className="navbar-item" href="https://pixi-photos.se">
                    {/*<img src="https://bulma.io/images/bulma-logo.png" width="112" height="28" />*/}
                    <div className="container">
                        <Link className={style.linkColor} to="index" onClick={DisableBurger} smooth={true} duration={1000}>
                                <strong className={`is-size-5 ${style.textColor}`}>PIXI</strong><strong className="is-size-5">Photos</strong>
                                <div className={`is-size-7`}>Shanti Lindström</div>
                                <div className={style.textSizeLocations}>PLATSER: TIERP, UPPSALA, GÄVLE, STOCKHOLM</div>
                        </Link>
                    </div>
                </a>


                <a role="button" className={`navbar-burger`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={() => { setisActive(!isActive) }}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>

            <div id="navbarBasicExample" className={`navbar-menu ${isActive ? "is-active" : ""}`}>
                <div className="navbar-end">
                    <a className="navbar-item">
                        <Link className={style.linkColor} to="fungerar" onClick={DisableBurger} smooth={true} duration={1000}>
                            <div className="mt-4 mb-4">
                                HUR FUNGERAR DET
                            </div>
                        </Link>
                    </a>

                    <a className="navbar-item" >
                        <Link className={style.linkColor} to="gallery" onClick={DisableBurger} smooth={true} duration={1000}>
                            <div className="mt-4 mb-4">
                                PORTFOLIO
                            </div>
                        </Link>
                    </a>
                    <a className={`${'navbar-item'}`} href="https://www.instagram.com/pixi_photos/" target="_blank" onClick={DisableBurger}>
                        <div className="mt-4 mb-4">

                            <svg className={style.iconInsta} viewBox="0 0 448 512" version="1.1" aria-hidden="true">
                                <path
                                    fillRule="evenodd"
                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                />
                            </svg>


                        </div>

                    </a>


                    <a className="navbar-item">
                        <Link className={style.linkColor} to="price" onClick={DisableBurger} smooth={true} duration={1000}>
                            <div className="mt-4 mb-4">
                                PRISER
                            </div>
                        </Link>
                    </a>

                </div>

                <div className="navbar-end">
                    <div className="navbar-item">
                        <div className="buttons">
                            <a className="button is-primary">
                                <Link className={`${style.HoverButtom} ${style.linkColorButtom}`} to="boka" onClick={DisableBurger} smooth={true} duration={1000}>
                                    <div className="mt-4 mb-4 ml-2 mr-2">
                                        <strong>BOKA</strong>
                                    </div>
                                </Link>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}