import logo from './logo.svg';
import './App.css';
import { NavBar } from './components/navbar/navbar';
import { LandingPage } from './components/landingpage/LandingPage';
import { Contact } from './components/pages/contact/contact';
import { Question } from './components/pages/questions/questions';
import { Information } from './components/pages/information/information';
import { HowTo } from './components/pages/howto/howto';
import { Prices } from './components/pages/prices/prices';
import { Portfolio } from './components/pages/portfolio/portfolio';

function App() {
  return (
    <div>
      <NavBar />
      <LandingPage />
      <Information/>
      <Prices/>
      <Contact />
      <Portfolio url="/images/1.png"/>
      <Portfolio url="/images/2.png"/>
      <Portfolio url="/images/3.png"/>
      <Portfolio url="/images/4.png"/>
      <Portfolio url="/images/5.png"/>
      <Portfolio url="/images/7.png"/>
      <Portfolio url="/images/8.png"/>
      <Portfolio url="/images/9.png"/>
      <Portfolio url="/images/11.png"/>
      <Portfolio url="/images/12.png"/>
      <Portfolio url="/images/13.png"/>
      <Portfolio url="/images/15.png"/>


    </div>
  );
}

export default App;
