import React from "react";
import style from "./LandingPage.module.css"

export function LandingPage() {
    return (
        <div className={`${style['fill-window']}`} id="index">
            <img className={`${style.imgPosition}`} alt="Background" src="/images/14.png" />

            <div className={`sections is-hidden-mobile is-desktop is-tablet is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd is-mobil is-overlay ${style.text}`}>
                <div className={`container`}>
                    <div>
                        <div className={`is-size-2 is-uppercase is-family-sans-serif`}>Välkommen
                        </div>
                        <div className={`is-size-4 is-uppercase is-family-sans-serif	`}>Låt oss skapa magiska bilder.</div>
                        <div className={`is-size-4 is-uppercase is-family-sans-serif	`}>Portabel Studio!</div>
                    </div>
                    <div className={`${style.text3}`}>
                        <div className={`is-size-6  is-family-sans-serif	`}>"Har du gått i tankarna att förnya dina foton för LinkedIn, Facebook, Tinder? Eller vill du ha kort på dina nära och kära?  Portabel Studio - Boka tid idag! <b>TIERP</b>, <b>UPPSALA</b>, <b>GÄVLE</b>, <b>STOCKHOLM</b>"</div>
                    </div>

                </div>
            </div>

            <div className={`sections is-hidden-desktop is-hidden-tablet is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd is-mobil is-overlay has-text-bottom pb-5 pl-5 ${style.text2}`}>
                <div className={`container`}>
                    <div>
                        <div className={`is-size-2 has-text-white-bis is-uppercase is-family-sans-serif`}>Låt oss skapa magiska bilder</div>
                        <div className={`is-size-4 has-text-white-bis is-uppercase is-family-sans-serif	`}>Portabel Studio</div>
                    </div>
                </div>
            </div>

        </div>

    );
}