import React from "react";
import style from "./prices.module.css"

export function Prices() {
    return (

        <div className={`${style['fill-window']}`} id="price">
            <section className="section">
                <div className="container">
                    <h1 className="title has-text-centered mt-6 pt-2">PRISER - HÖSTEN 2021</h1>

                    <div className="columns pt-4 is-centered">
                        <div className="px-4 pb-4">

                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>1500kr - Porträtt Standard</strong></span>
                                <p className="content">3 bilder i webbformat med enklare redigering för korregering av ljussättning och färger. Fotografering i portabel studio eller i naturligt ljus under ca 60min, ett klädombyte.</p>
                            </div>

                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>1999kr - Porträtt Signatur</strong></span>
                                <p className="content">3 bilder i webbformat med avancerad retuschering för att få bilden att poppa. Fotografering i portabel studio eller utomhus med utökad ljussättning under ca 60min, med 2-3 klädombyten.</p>

                            </div>
                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>1500kr - Porträtt Företag</strong></span>
                                <p className="content">Begär offert för användning av profilbilder för företagshemsida, linkedIn. Högupplösta bilder med fulla användningsrättigheter.</p>
                            </div>
                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>950kr i timmen - Övrig fotografering</strong></span>
                                <p className="content">Ex: produktfotografering, eventfotografering m.m. (ej bröllop).</p>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="section">
                <div className={`container`}>

                    <div className={`columns is-centered`}>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>750kr timmen - Övrig retuschering</strong></span>
                                <p className="content">Övrig redigering eller retuschering av bilder utöver tidigare överenskommet.</p>
                            </div>
                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>250kr - Högupplöst bild</strong></span>
                                <p className="content">Efterbeställning för att göra egna förstoringar.</p>

                            </div>
                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>1200Kr - Djur fotografering</strong></span>
                                <p className="content">Porträtt 1 bilder med avancerad retuschering för att få bilden att poppa. Fotografering i portabel studio eller utomhus under ca 60min.</p>
                            </div>
                        </div>

                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>25kr - milen</strong></span>
                                <p className="content">Då studion är portabel och utgångsläget är Tierp tillkommer milersättning.</p>
                            </div>
                        </div>
                        <div className="px-4 pb-4">
                            <div className={`box ${style.test}`}>
                                <span className="is-size-5"><strong>3500kr - Dejting</strong></span>
                                <p className="content">Under 2h kommer vi ta bilderna på olika lokationer för att skapa en variation av naturliga bilder. Som förklarar vem du är och vad du tycker om att göra.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </div>
    );
}

