import { React, useState } from "react";
import style from "./contact.module.css"

export function Contact() {
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [mob, setMob] = useState()
    const [text, setText] = useState()
    const [label, setLabel] = useState("SEND")
    const [hideform, setForm] = useState("");
    const [borderColor, setborderColor] = useState("");

    const thanks = hideform ? "" : "is-hidden";
    const bColor = borderColor ? "is-danger" : "";

    const send = () => {
        console.log("Hello" + name + email + text + mob)
        if (name && email && text && mob) {

            const content = {
                "embeds": [{
                    "title": name,
                    "description": email,
                    "footer": {
                        "text": mob + "\n" + text,
                    }
                }]
            }

            fetch('https://discord.com/api/webhooks/908429397944582164/TwaSAwNgJA0mPg1CIe12ypAlUDiof5wejqaJi4DDZqNkxwgr6d7gFoJfSY9EW9VTvZ4H', {
                method: 'POST',
                body: JSON.stringify(content),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(res => {
                    setName("")
                    setEmail("")
                    setMob("")
                    setText("")
                    setForm("is-hidden")

                })
        }
        else {
            setLabel("Error try again")
            setborderColor("is-danger")
        }
    }

    return (

        <div className={`has-background-primary-light ${style['fill-window']}`} id="boka">
            <section className="section mt-6 is-hidden-mobile is-desktop is-tablet is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd is-mobil">
                <div className="container">
                    <div className="columns pt-5 is-centered">
                        <div className="px-4 pb-4">
                            <div className={`box ${hideform}`}>

                                <span className={`is-centered ${style.headerText}`}><strong>Redo för fotografering?</strong></span>
                                <span className={style.headerTextCall}>Återkopplar efter klockan 18:00</span>
                                <input value={name} onChange={e => setName(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="text" placeholder="Namn:" />
                                <input value={mob} onChange={e => setMob(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="tel" placeholder="Mob:" />
                                <input value={email} onChange={e => setEmail(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="email" placeholder="E-Postadress:" />
                                <div className="field mb-2">
                                    <div className="control">
                                        <textarea value={text} onChange={e => setText(e.target.value)} className={`textarea ${bColor}`} placeholder="Meddelande:"></textarea>
                                    </div>
                                </div>
                                <button onClick={send} class="button is-primary is-fullwidth is-outlined">{label}</button>
                            </div>
                            <div className={`${style.thanks} ${thanks}`}>
                                <span className={`is-centered ${style.headerText}`}><strong>TACK! HA EN BRA DAG =)</strong></span>
                                <span className={style.headerTextCall}>VI HÖRS SNART!!</span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="section mt-6 is-hidden-desktop is-hidden-tablet is-three-quarters-mobile is-two-thirds-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd is-mobil">
                <div className="container">
                    <div className="columns pt-5 is-centered">
                        <div className="px-4 pb-4">
                            <div className={`box ${hideform}`}>
                                <span className={`is-centered ${style.headerTextMobile}`}><strong>REDO FÖR FOTOGRAFERING?</strong></span>
                                <span className={style.headerTextCallMobile}>Återkopplar efter klockan 18:00</span>
                                <input value={name} onChange={e => setName(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="text" placeholder="Namn:" />
                                <input value={mob} onChange={e => setMob(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="tel" placeholder="Mob:" />
                                <input value={email} onChange={e => setEmail(e.target.value)} className={`input has-text-black is-normal mb-2 ${bColor}`} type="email" placeholder="E-Postadress:" />
                                <div className="field mb-2">
                                    <div className="control">
                                        <textarea value={text} onChange={e => setText(e.target.value)} className={`textarea ${bColor}`} placeholder="Meddelande:"></textarea>
                                    </div>
                                </div>
                                <button onClick={send} class="button is-primary is-fullwidth is-outlined">{label}</button>
                            </div>
                            <div className={`${style.thanksMobile} ${thanks}`}>
                                <span className={`is-centered ${style.headerTextMobileThanks}`}><strong>TACK! HA EN BRA DAG =)</strong></span>
                                <span className={`is-centered ${style.TextMobileThanks}`}><strong>VI HÖRS SNART!!</strong></span>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


        </div>
    );
}

/*
        <div className={`${style['fill-window']}`}>
            <sections className="sections">
                <div className="container">
                    <div className="columns pt-5 is-centered">
                        <div className="px-4 pb-4">
                            <h1 className="title has-text-centered">VANLIGA FRÅGOR OCH SVAR</h1>
                            <div className={`columns ${style.test}`}>
                                <div>
                                    <input class={`input has-text-black is-normal mb-2`} type="text" placeholder="Namn" />
                                    <input class="input has-text-black is-normal mb-2" type="tel" placeholder="Mob" />
                                    <input class="input has-text-black is-normal mb-2" type="email" placeholder="E-Postadress" />
                                    <div class="field mb-2">
                                        <div class="control">
                                            <textarea class="textarea" placeholder="Meddelande"></textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </sections>

*/